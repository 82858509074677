import React from 'react';
import Slider from 'react-slick';
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import {Link, NavLink} from "react-router-dom";



function SliderTwo() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll:1,
    center:false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          SlidesToScroll: 1,
        }
      },
      {
        breakpoint: 575,
        settings: {
          sidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        }
      }
    ]
  }

    function smoothScroll(){
      document.querySelector('#about-us').scrollIntoView({
        behavior: 'smooth'
      });
    }

  // },[])

  return (


<section className="slider-area">
            <Slider className="slider-active" {...settings}>
              <div className="single-slider slider-bg slider-style-two">
                <div className="container-fluid container-full-padding">
                  <div className="row">
                    <div className="col-xl-6 col-lg-7 col-md-11">
                      <div className="slider-content ">
                        <Fade up delay={.4000}>
                        {/*<h6>header</h6>*/}
                        </Fade>
                        
                        <Fade up delay={.5000}> 
                        <h2>PolyDream <span>Studio</span> </h2>
                        </Fade>
                        <Fade up delay={.5000}>
                        <p>We create more than a Game!</p>
                        </Fade >
                       
                        <Fade up delay={.8000}>
                          <Link className="btn btn-style-two" to="/about-us">About Us</Link>
                        </Fade>
                      </div>
                    </div>

                    <Slide right delay={.4000}>

                      <div className="slider-img slider-img2 wow slideInRightS">
                        <img style={{width: '450px'}} src="assets/img/slider/cat_ciyinli.png" alt="" />
                      </div>
                    </Slide>
                  </div>
                </div>
                <div className="slider-circle-shape"><img src="assets/img/slider/slider_circle.png" alt="" className="rotateme" /></div>
              </div>
              {/*<div className="single-slider slider-bg slider-style-two" style={{backgroundImage: 'url(assets/img/slider/slider_bg.jpg)'}}>*/}
              {/*  <div className="container-fluid container-full-padding">*/}
              {/*    <div className="row">*/}
              {/*      <div className="col-xl-6 col-lg-7 col-md-11">*/}
              {/*        <div className="slider-content">*/}
              {/*          <Fade up delay={.4000}>*/}
              {/*          <h6>world gaming</h6>*/}
              {/*          </Fade>*/}
              {/*          <Fade up delay={.5000}>*/}
              {/*          <h2 >Cod <span>Modern</span> Warfare</h2>*/}
              {/*          </Fade>*/}
              {/*          <Fade up delay={.5000}>*/}
              {/*          <p>Find technology or people for digital projects in public sector and Find an individual specialist develope researcher.</p>*/}
              {/*          </Fade>*/}
              {/*          <Fade up delay={.8000}>*/}
              {/*          <a href="/#" className="btn btn-style-two">READ MORE</a>*/}
              {/*          </Fade>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*  <Slide right delay={.4000}>*/}
              {/*  <div className="slider-img"><img src="assets/img/slider/slider_img01.png" alt="" /></div>*/}
              {/*  </Slide>*/}
              {/*  <Slide left delay={.4000}>*/}
              {/*  <div className="slider-img slider-img2"><img src="assets/img/slider/slider_img02.png" alt="" /></div>*/}
              {/*  </Slide>*/}
              {/*  <div className="slider-circle-shape"><img src="assets/img/slider/slider_circle.png" alt="" className="rotateme" /></div>*/}
              {/*</div>*/}
            </Slider>
          </section>
  )
}

export default SliderTwo