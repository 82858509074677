import React,{ useEffect } from 'react'
import WOW from "wowjs";

function AboutInner() {
  useEffect(()=>{
    new WOW.WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: false,
      live: true,
      loop:Infinity,
    }).init();
  
    },[])
  return (
	<section className="inner-about-area fix" id={'about-us'}>
        <div className="container">
          <div className="row align-items-start">
            <div className="col-xl-7 col-lg-6 order-0 order-lg-2">
              <div className="inner-about-img" style={{ display: 'flex', justifyContent: 'center'}}>
                <img src="assets/img/images/pd.jpg" className="wow fadeInRight" data-wow-delay=".3s" alt="" style={{ width: '100%' }} />
              </div>
            </div>
            <div className="col-xl-5 col-lg-6">
              <div className="section-title title-style-three mb-25">
                <h2>about <span>US</span></h2>
              </div>
              <div className="inner-about-content">
                <h5>Hey there, welcome to PolyDream Studio website!</h5>
                <p>
                  Polydream Studio is a tight-knit team of 15 passionate gamers—artists, programmers, and modelers—who
                  wear multiple hats to bring ambitious ideas to life. The team thrives on creativity and determination,
                  driven by a genuine love for game development rather than big-name titles.
                </p>
                <p>
                  Creativity flows from the diverse skills of each member, enabling unique approaches to challenges. With a commitment to
                  crafting immersive and unforgettable gaming experiences, Polydream Studio aims to push creative
                  boundaries and deliver impactful games.
                </p>
                <p>
                  The studio envisions a future where its mark on the gaming world is undeniable. Over the next decade, Polydream Studio plans to grow into a global game
                  development powerhouse of 100+ talented individuals, recognized for creating cherished, emotion-filled
                  experiences enjoyed by players worldwide.
                </p>
              </div>
            </div>
          </div>
        </div>
      {/*<div className="inner-about-shape"><img src="assets/img/images/medale_shape.png" alt="" /></div>*/}
    </section>
  )
}

export default AboutInner